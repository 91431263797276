import { useState } from 'react';
import Link from 'next/link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnDownRight, faArrowRight, faArrowLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Navigation, Pagination, FreeMode } from "swiper";

import styles from './BrandSlider.module.scss'

import BlueChip from '../../public/brand-logos/svg/blue-chip.svg'
import Stiiizy from '../../public/brand-logos/svg/stiiizy.svg'
import Jeeter from '../../public/brand-logos/svg/jeeter.svg'
import HeavyHitters from '../../public/brand-logos/svg/heavy-hitters.svg'
import Kanha from '../../public/brand-logos/svg/kanha.svg'
import Wyld from '../../public/brand-logos/svg/wyld.svg'
import RawGarden from '../../public/brand-logos/svg/raw-garden.svg'
import Amplified from '../../public/brand-logos/svg/amplified.svg'
import AlienLabs from '../../public/brand-logos/svg/alien-labs.svg'
import Cannabiotix from '../../public/brand-logos/svg/cannabiotix.svg'
import Connected from '../../public/brand-logos/svg/connected.svg'
import Papa from '../../public/brand-logos/svg/papa-and-barkley.svg'
import Abx from '../../public/brand-logos/svg/abx.svg'
import Brands from "../../public/icons/grid-solid.svg";

import Button from '../../components/Button/button';

export default function Slider({ title, dark }) {

  const [swiper, setSwiper] = useState({});
  const brands = [
    {
      name: "Blue Chip",
      logo: <BlueChip />,
      color: "#fff",
      backgroundColor: "#3067a6",
    },
    {
      name: "Jeeter",
      logo: <Jeeter />,
      color: "#FDC601",
      backgroundColor: "#702E8F",
    },
    {
      name: "Stiiizy",
      logo: <Stiiizy />,
      color: "#fff",
      backgroundColor: "#000",

    },
    // {
    //   name: "CBX Cannabiotix",
    //   logo: <Cannabiotix />,
    //   color: "#E4DEC4",
    //   backgroundColor: "#35573E",

    // },
    {
      name: "AbsoluteXtracts",
      logo: <Abx />,
      color: "#000",
      backgroundColor: "#FEC52E",
    },
    {
      name: "Papa & Barkley",
      logo: <Papa />,
      color: "#fff",
      backgroundColor: "#5B7E96",

    },
    {
      name: "Connected Cannabis Co",
      logo: <Connected />,
      color: "#fff",
      backgroundColor: "#0198CD",

    },
    {
      name: "Alien+Labs",
      logo: <AlienLabs />,
      color: "#000",
      backgroundColor: "#79F104",
    },
    {
      name: "Wyld",
      logo: <Wyld />,
      color: "#000",
      backgroundColor: "#fff",
    },
    {
      name: "Heavy Hitters",
      logo: <HeavyHitters />,
      color: "#fff",
      backgroundColor: "#000",
    },
    {
      name: "Raw Garden™",
      logo: <RawGarden />,
      color: "#fff",
      backgroundColor: "#59611D",
    },

  ];

  return (
    <>
      <div className={`${styles.container} brands-slider`}>

        <div className={styles.header}>
          <div className={styles.header__content}>
            {/* <div className={styles.icon}><Brands /></div> */}
            <div className={styles.title}>
              <h2>{title}</h2>
            </div>

          </div>
          <Button type="link" href='/brands' className="sliderLink" prefetch={false} ariaLabel="View All Brands">
            <span>view all</span>
          </Button>
        </div>

        <Swiper
          onInit={(ev) => {
            setSwiper(ev)
          }}
          modules={[Navigation, Pagination, FreeMode]}
          className={styles.brandSlider}
          touchEventsTarget='wrapper'
          breakpoints={{
            1024: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 14,
            },
            550: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            }
          }}
          navigation={{
            prevEl: `#brand-prev`,
            nextEl: `#brand-next`,
            clickable: true,
          }}
          scrollbar={{
            hide: true,
            // dragSize:150,
          }}
        >

          <div className={styles.prev} id={`brand-prev`}>
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div className={styles.next} id={`brand-next`}>
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>

          {brands
            .map(brand =>
              <SwiperSlide key={brand.name} className={styles.slide}>
                <Link href={`/brands/${brand.name.replace(/ /g, "-")}`} passHref style={{ background: brand.backgroundColor, color: brand.color }} aria-label={brand.name}>
                  {brand.logo}
                </Link>
              </SwiperSlide>
            )
          }
          <SwiperSlide className={styles.lastSlide} >
            <Link href='/brands' >
              View All
            </Link>
          </SwiperSlide>

        </Swiper>
        {/* <div className={styles.footer}>
          <Link href='/shop' className={styles.link}>
            Browse All
          </Link>
        </div> */}
      </div>
    </>
  );
}